angular.module("app")
    .controller("paymentMethodController", ["$scope", "$log", "paymentMethodService", function ($scope, $log, paymentMethodService) {
        var vm = this;
        vm.loadingForSave = false;
        vm.methods = [];
        vm.pagedSet = {
            page: 0
        };
        vm.pageNotReady = vm.loading = true;
        vm.searching = vm.disableLoadMore = vm.includeInactive = vm.includeLinked = vm.loadingMore = false;

        vm.listPaymentMethods = listPaymentMethods;

        vm.inActive = false;
        vm.linked = false;
        vm.children = true;
        vm.search = {
            name: undefined
        };

        vm.loadMore = loadMore;
        vm.test = test;

        //init
        // listPaymentMethods(0);

        function test() {
            $scope.$emit('list:filtered');
        }

        function loadMore() {
            listPaymentMethods(vm.pagedSet.page);
        }


        function listPaymentMethods(page) {
            if (page === 0) {
                vm.methods = [];
            }

            vm.loadingMore = vm.disableLoadMore = true;

            var params = {
                page: page,
                inactive: vm.inActive,
                linked: vm.linked,
                children: vm.children,
                name: vm.search.name
            };

            return paymentMethodService.list(params)
                .then(function (pagedSet) {
                    vm.pagedSet = pagedSet;
                    vm.methods = vm.methods.concat(pagedSet.results);
                    vm.disableLoadMore = (pagedSet.page >= pagedSet.totalPages);
                }, function (error) {
                    $log.debug(error);
                }) ['finally'](function () {
                vm.loading = false;
                vm.inActiveLoading = vm.linkedLoading = vm.pageNotReady = vm.loadingMore = false;
            });
        }

        vm.removePaymentMethod = function (organisationReference, paymentMethodReference) {
            vm.loading = true;
            return paymentMethodService.removePaymentMethod(organisationReference, paymentMethodReference)
                .then(function (results) {
                    listPaymentMethods(0);
                })
                ['finally'](function () {
                vm.loading = false;
            });
        };
    }]);